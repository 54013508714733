import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

const DATADOG_APPLICATION_ID = process.env.REACT_APP_DATADOG_APPLICATION_ID;
const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
const DATADOG_SITE = process.env.REACT_APP_DATADOG_SITE;

export function useDataDog() {
  useEffect(() => {
    if (DATADOG_APPLICATION_ID && DATADOG_CLIENT_TOKEN && DATADOG_SITE) {
      datadogRum.init({
        applicationId: DATADOG_APPLICATION_ID,
        clientToken: DATADOG_CLIENT_TOKEN,
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: DATADOG_SITE,
        //  service: 'my-web-application',
        env: process.env.NODE_ENV,
        //  version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        allowedTracingUrls: [
          'https://vendelux.com',
          /https:\/\/.*\.vendelux\.com/,
        ],
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        enablePrivacyForActionName: true,
      });
    }
  }, []);
}
