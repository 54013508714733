import React, { Fragment, SyntheticEvent } from 'react';
import {
  Autocomplete,
  Container,
  Divider,
  Grid,
  TextField,
  Theme,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material';
import { css } from '@emotion/css';
import { East } from '@mui/icons-material';

const labelStyle = (theme: Theme) => css`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  padding: ${theme.spacing(2)}px;
  background-color: ${theme.palette.grey[100]};
  border-radius: ${theme.shape.borderRadius}px;
  gap: ${theme.spacing(6)}px;
`;

export interface CsvUploadModalFieldInfo {
  fieldName: string;
  fieldLabel: string;
  fieldDescription: string;
}
interface UploadModalProps {
  label: string;
  file: File | null;
  columns: string[];
  setSelectedColumn?: React.Dispatch<
    React.SetStateAction<{
      fieldConfig: CsvUploadModalFieldInfo;
      value: string;
    }>
  >;
  csvError: string;
  mainLabel: string;
  fields: CsvUploadModalFieldInfo[];
}

interface CsvUploadModalRowProps {
  fieldConfig: CsvUploadModalFieldInfo;
  columns: string[];
  onChange: (
    e: SyntheticEvent,
    fieldConfig: CsvUploadModalFieldInfo,
    value: string | null
  ) => void;
  csvError: string;
}

export interface CsvUploadModalFieldConfig
  extends Record<string, CsvUploadModalFieldInfo[]> {}

export const fieldConfigurations: CsvUploadModalFieldConfig = {
  organizations: [
    {
      fieldName: 'domain',
      fieldLabel: 'Domain',
      fieldDescription: 'Column containing domain',
    },
  ],
  people: [
    {
      fieldName: 'email',
      fieldLabel: 'Email',
      fieldDescription: 'Column containing email',
    },
  ],
  'job-titles-keywords': [
    {
      fieldName: 'job_title',
      fieldLabel: 'Job titles',
      fieldDescription: `Column containing job titles`,
    },
  ],
};

export function CsvUploadModalRow({
  fieldConfig,
  columns,
  onChange,
  csvError,
}: CsvUploadModalRowProps) {
  const theme = useTheme();
  const { fieldName, fieldLabel } = fieldConfig;

  return (
    <Grid container direction="row" gap={theme.spacing(3)} alignItems="center">
      <Grid item>
        <span>
          <b>Match</b>
        </span>
        <span className={labelStyle(theme)}>
          {fieldLabel}
          <East />
        </span>
      </Grid>
      <Grid item sx={{ flexGrow: 1 }}>
        <span>{fieldLabel}</span>
        <Autocomplete
          readOnly={csvError !== ''}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="Select column header"
              name={fieldName}
            />
          )}
          onChange={(e, value) => {
            onChange(e, fieldConfig, value);
          }}
          options={columns}
        />
      </Grid>
    </Grid>
  );
}

export function CsvUploadModal({
  file,
  columns,
  setSelectedColumn,
  csvError,
  fields,
}: UploadModalProps) {
  const theme = useTheme();

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        overflowY: 'scroll',
      }}
    >
      <Typography color={theme.palette.primary.main}>{file?.name}</Typography>

      {fields.map((field, index) => (
        <Fragment key={index}>
          <CsvUploadModalRow
            fieldConfig={field}
            columns={columns}
            csvError={csvError}
            onChange={(_e, fieldConfig, value) => {
              if (setSelectedColumn) {
                if (value)
                  setSelectedColumn({
                    fieldConfig,
                    value,
                  });
                else setSelectedColumn({ fieldConfig, value: '' });
              }
            }}
          />

          {index < fields.length - 1 && <Divider textAlign="left">AND</Divider>}
        </Fragment>
      ))}
      {csvError && (
        <Typography color={theme.palette.error.main}>{csvError}</Typography>
      )}
    </Container>
  );
}
