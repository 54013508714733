import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Input,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
} from '@mui/material';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid-premium';
import React, { useCallback, useState } from 'react';
import { LoadingIconForButton } from 'components/common/Widgets/LoadingIconForButton';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';

interface DataGridAddFilterButtonProps<T extends GridValidRowModel> {
  isLoading: boolean;
  hiddenFilterableColumns: GridColDef<T>[];
  handleOnClick: (column: GridColDef<T>) => void;
  isDisabled?: boolean;
}

export const DataGridAddFilterButton = <T extends GridValidRowModel>({
  isLoading,
  isDisabled,
  hiddenFilterableColumns,
  handleOnClick,
}: DataGridAddFilterButtonProps<T>) => {
  // to handle opening / closing of the filter dropdown menu
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [searchText, setSearchText] = useState<string>('');

  // click to open the filter menu
  const handleOpenMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );

  // close the menu without applying
  const handleCancelMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  // filter by search text and filter buttons that are already active
  const searchTextParts = searchText.toLowerCase().split(/\s+/);

  // check if each search text part is a prefix of any name part
  const filteredButtons = hiddenFilterableColumns.filter((column) => {
    const nameParts = column.headerName?.toLowerCase().split(/\s+/);
    return searchTextParts.every((searchPart) =>
      nameParts?.some((namePart) => namePart.startsWith(searchPart))
    );
  });

  return (
    <>
      <Button
        color="inherit"
        variant="rounded"
        sx={{
          borderStyle: 'dashed',
          color: 'text.secondary',
          whiteSpace: 'nowrap',
        }}
        disabled={isLoading || isDisabled}
        endIcon={isLoading ? <LoadingIconForButton /> : <AddIcon />}
        onClick={handleOpenMenu}
      >
        Add filter
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleCancelMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Card sx={{ minWidth: 200 }}>
          <CardHeader
            title={'Add filter'}
            titleTypographyProps={{ variant: 'body1', fontWeight: 'bold' }}
            action={
              <IconButton size="small" onClick={handleCancelMenu}>
                <CloseIcon />
              </IconButton>
            }
          />
          <Divider />
          <Input
            startAdornment={<Search sx={{ pr: 1 }} fontSize="small" />}
            placeholder="Search filters"
            fullWidth
            disableUnderline
            margin="dense"
            value={searchText}
            sx={{
              p: 2,
            }}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Divider />
          <CardContent
            sx={{
              p: '0 !important',
              maxHeight: 350,
              overflow: 'auto',
            }}
          >
            <MenuList sx={{ p: 0 }}>
              {filteredButtons.length ? (
                filteredButtons.map((column, index) => (
                  <MenuItem
                    key={`${column.field}-${index}`}
                    onClick={() => {
                      handleOnClick(column);
                      setAnchorEl(null); // close the menu after adding a new filter button
                    }}
                  >
                    <ListItemText primary={column.headerName} />
                  </MenuItem>
                ))
              ) : (
                <MenuItem sx={{ p: 4 }} disabled>
                  <ListItemText primary="No filters found." />
                </MenuItem>
              )}
            </MenuList>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
};
