import { ApiResponseBase } from 'utils/api-response';
import { api } from '.';
import { endpoints } from 'variables/endpoint-urls';
import { reportResultInSnack } from './util';
import { strings } from 'variables/messages';

export type CrmFilterOption = {
  uuid: string;
  label: string;
};

export type CrmFilterOptions = {
  results: {
    grouped_crm_filters: {
      OWNER: CrmFilterOption[];
      DEAL_STAGE: CrmFilterOption[];
      CONTACT_STATUS: CrmFilterOption[];
    };
  };
};

const eventsErrorMessages = strings.crm.error;

export const crmApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCrmFilterOptions: builder.query<
      ApiResponseBase<CrmFilterOptions>,
      string
    >({
      query: (uuid) => ({
        method: 'GET',
        url: endpoints.crm.get.crmFilters(uuid),
      }),
      onQueryStarted: (args, { dispatch, queryFulfilled }) => {
        reportResultInSnack({
          dispatch,
          queryFulfilled,
          defaultErrorMessage: eventsErrorMessages.crmFilters,
        });
      },
    }),
  }),
});
