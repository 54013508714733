import React from 'react';
import { ReactComponent as NotFoundSVG } from 'assets/img/icons/common/notFound.svg';
import { Box, Button, Grid, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { css } from '@emotion/css';

const ButtonStyle = css`
  &:hover {
    color: #fff;
  }
`;

const NotFoundView = () => {
  return (
    <Box margin={16}>
      <Grid
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        spacing={5}
        container
      >
        <Grid item>
          <NotFoundSVG />
        </Grid>
        <Grid item>
          <Typography variant="h5" color="textPrimary" align="center">
            This page doesn't exist
          </Typography>
          <Typography variant="body1" color="textPrimary" align="center">
            Please try searching for something else
          </Typography>
        </Grid>
        <Grid item>
          <Button
            size="large"
            variant="contained"
            color="primary"
            href="/app/events/search/"
            startIcon={<SearchIcon />}
            className={ButtonStyle}
          >
            Search events
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotFoundView;
