import React, { useCallback, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Input,
  Stack,
  Typography,
} from '@mui/material';
import { GridValidRowModel } from '@mui/x-data-grid-premium';
import {
  DataGridFilterButtonChip,
  FilterChipItem,
  checkTwoChipsIfEqual,
} from './DataGridFilterButtonChip';
import { arraysEqual, formatNumber, pluralize } from 'utils/util';
import {
  CustomGridColDef,
  DEFAULT_VALUES_FOR_PROPS,
} from '../../DataGridProvider';
import CloseIcon from '@mui/icons-material/Close';
import Search from '@mui/icons-material/Search';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';

interface DataGridFilterButtonContentProps<T extends GridValidRowModel> {
  column: CustomGridColDef<T>;
  chipItems: FilterChipItem[];
  selectedChipItems: FilterChipItem[];
  setSelectedChipItems: React.Dispatch<React.SetStateAction<FilterChipItem[]>>;
  currentlyFilteredChipItems: FilterChipItem[];
  handleSelectItem: (item: FilterChipItem) => void;
  handleApply: () => void;
  handleCancel: () => void;
  getHideNoneFilterChip?: (value: unknown) => boolean;
  hideNoneFilterChip?: boolean;
}

export const DataGridFilterButtonContent = <T extends GridValidRowModel>({
  column,
  chipItems,
  selectedChipItems,
  setSelectedChipItems,
  currentlyFilteredChipItems,
  handleSelectItem,
  handleApply,
  handleCancel,
}: DataGridFilterButtonContentProps<T>) => {
  const maxChipCount =
    column.filterMaxChipCount ?? DEFAULT_VALUES_FOR_PROPS.filterMaxChipCount;
  const hideSearchBox = column.hideFilterSearchBox;
  const placeholder =
    column.filterSearchBoxPlaceholder ??
    `Search for ${column.headerName?.toLowerCase()}…`;

  // filter items by search text
  const [searchText, setSearchText] = useState<string>('');
  const lowerCaseSearchText = searchText.toLowerCase();
  const filteredItems = chipItems.filter((item) => {
    const lowerCaseLabel = item.label.toLowerCase();
    return (
      lowerCaseLabel.startsWith(lowerCaseSearchText) ||
      lowerCaseLabel.includes(lowerCaseSearchText)
    );
  });

  // show a maximum of maxChipCount items
  const [currentMaxChipCount, setCurrentMaxChipCount] =
    useState<number>(maxChipCount);
  const visibleItems = filteredItems.slice(0, currentMaxChipCount);

  // various booleans to determine what to show
  const tooManyItemsToShow = chipItems.length > 3;
  const shouldShowSearchBox = !hideSearchBox && tooManyItemsToShow;
  const nothingHasChanged = arraysEqual(
    selectedChipItems.map((item) => item.label),
    currentlyFilteredChipItems.map((item) => item.label)
  );
  const allItemsSelected = selectedChipItems.length === chipItems.length;
  const allVisibleSelected = visibleItems.length === selectedChipItems.length;
  const allItemsVisible = filteredItems.length === visibleItems.length;
  const itemsWereFilteredOut = filteredItems.length < chipItems.length;

  // amount of chips to show in the "show more" button
  const showMoreNumber = useMemo(
    () => Math.min(maxChipCount, filteredItems.length - visibleItems.length),
    [maxChipCount, filteredItems.length, visibleItems.length]
  );

  const columnIsSingleSelect =
    column.type === 'boolean' || column.type === 'singleSelect';

  // #region handlers

  const handleSelectVisibleItems = useCallback(() => {
    setSelectedChipItems(visibleItems);
  }, [visibleItems, setSelectedChipItems]);

  const handleSelectAllItems = useCallback(() => {
    setSelectedChipItems(filteredItems);
  }, [filteredItems, setSelectedChipItems]);

  const handleClearAllItems = useCallback(() => {
    setSelectedChipItems([]);
  }, [setSelectedChipItems]);

  const handleShowMore = useCallback(() => {
    setCurrentMaxChipCount(
      (prevMaxChipCount) => prevMaxChipCount + showMoreNumber
    );
  }, [showMoreNumber, setCurrentMaxChipCount]);

  const handleShowAll = useCallback(() => {
    setCurrentMaxChipCount(chipItems.length);
  }, [chipItems.length, setCurrentMaxChipCount]);

  // #endregion

  // caption logic depending on if items were filtered out / visible / selected
  const filteredOutCaption = itemsWereFilteredOut ? (
    <Typography component="span" variant="caption" sx={{ opacity: 0.7 }}>
      {` (${chipItems.length - filteredItems.length} filtered out)`}
    </Typography>
  ) : null;
  const subtitle = selectedChipItems.length ? (
    <Typography variant="caption">
      {`Selected ${formatNumber(selectedChipItems.length)} of ${formatNumber(
        filteredItems.length
      )} ${pluralize('option', filteredItems.length, '', 's')}`}
      {filteredOutCaption}
    </Typography>
  ) : (
    <Typography variant="caption">
      {`Showing ${
        allItemsVisible
          ? `${filteredItems.length > 1 ? 'all' : ''}`
          : `${formatNumber(visibleItems.length)} of `
      } ${formatNumber(filteredItems.length)} ${pluralize(
        'option',
        filteredItems.length,
        '',
        's'
      )}`}
      {filteredOutCaption}
    </Typography>
  );

  return (
    <Card sx={{ maxWidth: 500, minWidth: 350 }}>
      <CardHeader
        sx={{ pb: 2, display: 'flex', alignItems: 'center' }}
        title={
          <Stack>
            <Typography variant="body1" fontWeight="bold">
              {column.headerName}
            </Typography>
            {tooManyItemsToShow && subtitle}
          </Stack>
        }
        titleTypographyProps={{ variant: 'body1', fontWeight: 'bold' }}
        action={
          <IconButton size="small" onClick={handleCancel}>
            <CloseIcon />
          </IconButton>
        }
      />
      <Divider />
      {shouldShowSearchBox && (
        <CardContent sx={{ p: '0 !important' }}>
          <Input
            startAdornment={<Search sx={{ pr: 1 }} fontSize="small" />}
            placeholder={placeholder}
            fullWidth
            disableUnderline
            margin="dense"
            sx={{
              p: 2,
            }}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
          />
          <Divider />
        </CardContent>
      )}
      <CardContent
        sx={{
          pt: 1,
          pb: 0,
        }}
      >
        {visibleItems.length ? (
          <Stack
            flexWrap="wrap"
            justifyContent="flex-start"
            alignItems="center"
            direction="row"
            sx={{
              ml: -1,
              pb: 1,
              maxHeight: 300,
              overflow: 'auto',
            }}
          >
            {visibleItems.map((item, index) => {
              const itemIsSelected = selectedChipItems.some((selectedItem) =>
                checkTwoChipsIfEqual(selectedItem, item)
              );

              return (
                <DataGridFilterButtonChip<T>
                  key={`${item.label}-${item.count}-${index}`}
                  item={item}
                  column={column}
                  hideCount={column.hideFilterCount}
                  itemIsSelected={itemIsSelected}
                  selectItem={handleSelectItem}
                />
              );
            })}
            {/* show more button if there are less visible than total that can be visible */}
            {visibleItems.length < filteredItems.length && (
              <DataGridFilterButtonChip
                item={{
                  label: `Show ${showMoreNumber} more`,
                  value: '',
                  count: 1, // count is hidden anyway (0 count would grey it out)
                }}
                column={column}
                hideCount
                itemIsSelected={allItemsSelected}
                selectItem={handleShowMore}
                startIcon={<VisibilityRoundedIcon fontSize="small" />}
              />
            )}
            {/* show all button if there are a lot more chips that can be shown */}
            {!column.filterChipDisableShowAll &&
              filteredItems.length - visibleItems.length >
                DEFAULT_VALUES_FOR_PROPS.amountAtWhichShowAllButtonIsShown && (
                <DataGridFilterButtonChip
                  item={{
                    label: 'Show all',
                    value: '',
                    count: 1, // count is hidden anyway (0 count would grey it out)
                  }}
                  column={column}
                  hideCount
                  itemIsSelected={allItemsSelected}
                  selectItem={handleShowAll}
                  startIcon={<VisibilityIcon fontSize="small" />}
                />
              )}
          </Stack>
        ) : (
          <Box sx={{ py: 4, maxHeight: 300, overflow: 'auto' }}>
            <Typography variant="body2">
              No options found to filter by.
            </Typography>
          </Box>
        )}
        <Divider />
      </CardContent>
      <CardActions>
        <Stack direction="row" width="100%" justifyContent="space-between">
          <Stack direction="row" spacing={2}>
            {!allItemsSelected &&
            tooManyItemsToShow &&
            !columnIsSingleSelect ? (
              <Button variant="text" onClick={handleSelectAllItems}>
                Select all
              </Button>
            ) : null}
            {!allItemsVisible &&
            !allVisibleSelected &&
            !allItemsSelected &&
            tooManyItemsToShow &&
            !columnIsSingleSelect ? (
              <Button variant="text" onClick={handleSelectVisibleItems}>
                Select visible
              </Button>
            ) : null}
            {selectedChipItems.length > 0 ? (
              <Button variant="text" onClick={handleClearAllItems}>
                {columnIsSingleSelect ? 'Reset' : 'Clear all'}
              </Button>
            ) : null}
          </Stack>
          <Stack direction="row">
            <Button variant="text" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="text"
              disabled={nothingHasChanged}
              onClick={handleApply}
              data-pendo-filter-name={column.headerName}
              data-pendo-selected-count={selectedChipItems.length}
              data-pendo-selected-names={selectedChipItems
                .map((item) => item.label)
                .join(',')}
            >
              Apply
            </Button>
          </Stack>
        </Stack>
      </CardActions>
    </Card>
  );
};
