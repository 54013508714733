import { Button, Popover } from '@mui/material';
import {
  GridFilterItem,
  GridValidRowModel,
  useGridApiContext,
} from '@mui/x-data-grid-premium';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PropCounts } from 'components/common/DataGrid/useGetRowCounts';
import CloseIcon from '@mui/icons-material/Close';
import { DataGridFilterButtonContent } from './DataGridFilterButtonContent';
import {
  CustomGridColDef,
  DEFAULT_VALUES_FOR_PROPS,
} from '../../DataGridProvider';
import {
  FilterChipItem,
  checkTwoChipsIfEqual,
} from './DataGridFilterButtonChip';
import { useUpdateFilterModel } from 'hooks/common/DataGrid/useUpdateFilterModel';
import { capitalizeFirstChar } from 'utils/util';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

interface DataGridFilterButtonProps<T extends GridValidRowModel> {
  column: CustomGridColDef<T>;
  handleRemoveFilterButton: (column: CustomGridColDef<T>) => void;
  totalCounts?: PropCounts;
  filteredRowCounts?: PropCounts;
  isLoading?: boolean;
  isDisabled?: boolean;
  isOpen?: boolean;
}

export const DataGridFilterButton = <T extends GridValidRowModel>({
  column,
  handleRemoveFilterButton,
  totalCounts,
  filteredRowCounts,
  isLoading,
  isDisabled,
  isOpen = false,
}: DataGridFilterButtonProps<T>) => {
  const apiRef = useGridApiContext();
  const columnVisibilityModel =
    apiRef.current.state.columns.columnVisibilityModel;
  const booleanLabel = useMemo(() => {
    return column.booleanLabel ?? DEFAULT_VALUES_FOR_PROPS.booleanLabel;
  }, [column.booleanLabel]);

  // which row counts to use
  const columnIsSingleSelect =
    column.type === 'boolean' || column.type === 'singleSelect';

  const getChipLabel = useCallback(
    (value) => {
      return column.type === 'boolean'
        ? (typeof value === 'string' && value === 'true') || // boolean type GridColDef value is a string not boolean
          (typeof value === 'boolean' && value)
          ? booleanLabel[0]
          : booleanLabel[1]
        : typeof column.getFilterChipLabel === 'function'
        ? column.getFilterChipLabel(value)
        : capitalizeFirstChar(String(value));
    },
    [column, booleanLabel]
  );

  const [filterModel, updateFilterModel] = useUpdateFilterModel(apiRef);
  const otherFiltersAreActive = useMemo(() => {
    return !!filterModel.items.find((item) => item.field !== column.field);
  }, [filterModel.items, column.field]);

  const rowCountsToUseForCounts = otherFiltersAreActive
    ? filteredRowCounts
    : totalCounts;

  // figure out if there are any currently filtered chip item from the filtermodel.items (there should only ever be one)
  const currentlyFilteredGridItem = useMemo(() => {
    return filterModel.items.find(
      (item) => item.field === column.field && item.value !== ''
    );
  }, [column.field, filterModel.items]);
  const currentlyFilteredChipItems: FilterChipItem[] = useMemo(() => {
    if (currentlyFilteredGridItem == null) return [];
    if (columnIsSingleSelect) {
      return [
        {
          label: getChipLabel(currentlyFilteredGridItem.value),
          value: currentlyFilteredGridItem.value,
          count:
            rowCountsToUseForCounts?.get(currentlyFilteredGridItem.value)
              ?.count ?? 0,
          rowIds:
            rowCountsToUseForCounts?.get(currentlyFilteredGridItem.value)
              ?.rowIds ?? [],
        },
      ];
    } else {
      return currentlyFilteredGridItem.value.map((value: string) => {
        return {
          label: getChipLabel(value),
          value,
          count: rowCountsToUseForCounts?.get(value)?.count ?? 0,
          rowIds:
            rowCountsToUseForCounts?.get(currentlyFilteredGridItem.value)
              ?.rowIds ?? [],
        };
      });
    }
  }, [
    columnIsSingleSelect,
    currentlyFilteredGridItem,
    getChipLabel,
    rowCountsToUseForCounts,
  ]);

  // update selected items when filter model changes
  useEffect(() => {
    setSelectedChipItems(currentlyFilteredChipItems);
  }, [currentlyFilteredChipItems, filterModel.items]);

  const [selectedChipItems, setSelectedChipItems] = useState<FilterChipItem[]>(
    []
  ); // selected NOT applied or refined yet

  const getChipItems = useCallback(() => {
    if (column.type === 'boolean') {
      return [
        {
          label: booleanLabel[0],
          value: true,
          count: rowCountsToUseForCounts?.get(true)?.count ?? 0,
          rowIds: rowCountsToUseForCounts?.get(true)?.rowIds ?? [],
        },
        {
          label: booleanLabel[1],
          value: false,
          count: rowCountsToUseForCounts?.get(false)?.count ?? 0,
          rowIds: rowCountsToUseForCounts?.get(false)?.rowIds ?? [],
        },
      ];
    } else if (
      column.type === 'singleSelect' &&
      'valueOptions' in column &&
      column.valueOptions &&
      typeof column.valueOptions !== 'function'
    ) {
      return column.valueOptions.map((value) => {
        return {
          label: getChipLabel(value),
          value,
          count: rowCountsToUseForCounts?.get(value)?.count ?? 0,
          rowIds: rowCountsToUseForCounts?.get(value)?.rowIds ?? [],
        };
      });
    } else {
      return Array.from(totalCounts?.keys() ?? [])
        .map((value) => {
          return {
            label: getChipLabel(value),
            value,
            count: rowCountsToUseForCounts?.get(value)?.count ?? 0,
            rowIds: rowCountsToUseForCounts?.get(value)?.rowIds ?? [],
          };
        })
        .sort((a, b) => {
          // prioritize currently filtered items
          const aIsFiltered = currentlyFilteredChipItems.some((item) =>
            checkTwoChipsIfEqual(item, a)
          );
          const bIsFiltered = currentlyFilteredChipItems.some((item) =>
            checkTwoChipsIfEqual(item, b)
          );
          if (aIsFiltered !== bIsFiltered) return aIsFiltered ? -1 : 1;

          // move chip with undefined value to the end
          if (a.label.length === 0 || a.value === undefined) return 1;
          if (a.label.length === 0 || b.value === undefined) return -1;

          // sort by the defined sort function or by count
          if (typeof column.filterChipSort === 'function') {
            return column.filterChipSort(a.label, b.label);
          }
          return a.count > b.count ? -1 : 1;
        });
    }
  }, [
    column,
    booleanLabel,
    rowCountsToUseForCounts,
    currentlyFilteredChipItems,
    totalCounts,
    getChipLabel,
  ]);

  const chipItems = getChipItems();

  const buttonHasSelectedItems =
    typeof currentlyFilteredGridItem !== 'undefined';
  const buttonLabel = buttonHasSelectedItems
    ? `${
        currentlyFilteredChipItems[0].label !== ''
          ? currentlyFilteredChipItems[0].label
          : DEFAULT_VALUES_FOR_PROPS.filterChipBlankText ??
            column.filterChipBlankText
      }${
        currentlyFilteredChipItems.length > 1
          ? ` +${currentlyFilteredChipItems.length - 1}`
          : ''
      }`
    : column.headerName ?? '';

  // if longer than column.filterChipTruncateLength, truncate and add ellipsis
  const filterChipTruncateLength = column.filterChipTruncateLength ?? 20;
  const buttonLabelTruncated =
    buttonLabel.length > filterChipTruncateLength
      ? `${buttonLabel.slice(0, filterChipTruncateLength)}…`
      : buttonLabel;

  // custom label for the button (if the column has a getFilterButtonLabel function, use that)
  const customLabel =
    typeof column.getFilterButtonLabel === 'function' && !isLoading
      ? column.getFilterButtonLabel(buttonHasSelectedItems, chipItems) ??
        buttonLabelTruncated
      : buttonLabelTruncated;

  // deal with opening / closing the popover
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [filterButtonOpen, setFilterButtonOpen] = useState(isOpen);

  // set anchorEl on page load
  useEffect(() => {
    if (buttonRef.current) {
      setAnchorEl(buttonRef.current);
    }
  }, []);

  // #region handlers

  const handleSelectItem = useCallback(
    (item) => {
      if (columnIsSingleSelect) {
        // toggle on or off if it's a single select column
        setSelectedChipItems((prevSelectedItems) => {
          const itemIsSelected = prevSelectedItems.some((chip) =>
            checkTwoChipsIfEqual(chip, item)
          );
          if (itemIsSelected) {
            return [];
          }
          return [item];
        });
      } else {
        setSelectedChipItems((prevSelectedItems) => {
          const itemIsSelected = prevSelectedItems.some((chip) =>
            checkTwoChipsIfEqual(chip, item)
          );
          if (itemIsSelected) {
            return prevSelectedItems.filter(
              (chip) => !checkTwoChipsIfEqual(chip, item)
            );
          }
          return [...prevSelectedItems, item];
        });
      }
    },
    [columnIsSingleSelect, setSelectedChipItems]
  );

  const handleCancel = useCallback(() => {
    setFilterButtonOpen(false);
    setSelectedChipItems(currentlyFilteredChipItems);

    // hide the filter button if it was added but not applied and if it's not a default filter and it there's nothing active in this filter
    if (!column.defaultFilter && !buttonHasSelectedItems) {
      handleRemoveFilterButton(column);
    }
  }, [
    handleRemoveFilterButton,
    column,
    currentlyFilteredChipItems,
    buttonHasSelectedItems,
  ]);

  const handleApply = useCallback(() => {
    setFilterButtonOpen(false);
    const filterItem: GridFilterItem[] = [
      {
        id: column.field,
        field: column.field,
        operator:
          selectedChipItems.length === 0
            ? 'delete'
            : column.filterOperatorToUse ??
              (column.type === 'singleSelect' || column.type === 'boolean'
                ? 'is'
                : DEFAULT_VALUES_FOR_PROPS.filterOperatorToUse),
        value:
          column.type === 'boolean' || column.type === 'singleSelect'
            ? String(selectedChipItems[0].value)
            : selectedChipItems.map((item) => {
                if (item.value) return item.value;
                else if (
                  column.type === 'number' ||
                  column.type === 'currency'
                ) {
                  return '0';
                } else return '';
              }),
      },
    ];
    updateFilterModel(filterItem);

    // if this isn't a default filter and if there are no items selected, remove the filter button from the toolbar
    if (
      (filterItem.length === 0 || filterItem[0]?.operator === 'delete') &&
      !column.defaultFilter
    ) {
      handleRemoveFilterButton(column);
    }

    // if we're adding a filter for a hidden column, show the column
    if (
      columnVisibilityModel[column.field] === false && // column is hidden
      filterItem[0]?.operator !== 'delete' // we're adding a filter
    ) {
      apiRef.current.setColumnVisibility(column.field, true);
    }
  }, [
    apiRef,
    column,
    columnVisibilityModel,
    selectedChipItems,
    updateFilterModel,
    handleRemoveFilterButton,
  ]);

  // #endregion

  return (
    <>
      <Button
        ref={buttonRef}
        color={buttonHasSelectedItems ? 'primary' : 'grey'}
        variant={buttonHasSelectedItems ? 'rounded' : 'roundedOutlined'}
        onClick={(event) => {
          if (isLoading) return;
          setFilterButtonOpen(true);
          setAnchorEl(event.currentTarget);
        }}
        disabled={isDisabled || isLoading}
        endIcon={
          buttonHasSelectedItems ? (
            <CloseIcon
              onClick={(event) => {
                updateFilterModel([
                  {
                    id: column.field,
                    field: column.field,
                    operator: 'delete',
                    value: [],
                  },
                ]);
                setFilterButtonOpen(false);
                setSelectedChipItems([]);
                // if this isn't a default filter, remove the filter button from the toolbar
                if (!column.defaultFilter) {
                  handleRemoveFilterButton(column);
                }
                event.stopPropagation(); // prevent click of button
              }}
              fontSize="small"
            />
          ) : (
            <ExpandMoreIcon fontSize="small" />
          )
        }
        sx={{
          whiteSpace: 'nowrap',
          flexShrink: 0,
        }}
      >
        {customLabel}
      </Button>
      {anchorEl && !isLoading ? (
        <Popover
          open={filterButtonOpen}
          anchorEl={anchorEl}
          onClose={handleCancel}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <DataGridFilterButtonContent
            column={column}
            chipItems={chipItems}
            selectedChipItems={selectedChipItems}
            setSelectedChipItems={setSelectedChipItems}
            handleSelectItem={handleSelectItem}
            handleApply={handleApply}
            handleCancel={handleCancel}
            currentlyFilteredChipItems={currentlyFilteredChipItems}
          />
        </Popover>
      ) : null}
    </>
  );
};
