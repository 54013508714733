import { WizardQuestionPage } from 'components/Wizard/WizardQuestionPage';
import { withAppAuthenticationRequired } from 'components/common/Auth/withAppAuthenticationRequired';
import { listWizardConfig } from './listWizardConfig';
import { useParams } from 'react-router';
import { useListWizard } from 'components/Wizard/useListWizard';
import { useEffect, useState } from 'react';
import { LoadingIcon } from 'components/common/Widgets/LoadingIcon';

function _NewListWizardPage() {
  const [hasBootstrapped, setHasBootstrapped] = useState<boolean>(false);
  const { questionId } = useParams<{ questionId: string }>(); // Type assertion

  const { wizard, callbacks } = useListWizard({
    config: listWizardConfig,
    mode: 'create',
  });

  // If we just clicked 'new list', clear the storage and start fresh.
  useEffect(() => {
    // Ensure that localStorage is clear of any lists we were creating/editing previously.
    if (wizard && !questionId && !hasBootstrapped) {
      wizard.clearStorage();
    }
    setHasBootstrapped(true);
  }, [questionId, wizard, hasBootstrapped]);

  // Default to first question is none specified in the
  const question = wizard.questions.byId(
    questionId ? questionId : listWizardConfig.questions[0].id
  );

  if (!hasBootstrapped) {
    return <LoadingIcon />;
  }

  return (
    <WizardQuestionPage
      wizard={wizard}
      currentQuestionId={question.id}
      callbacks={callbacks}
    />
  );
}

export const NewListWizardPage =
  withAppAuthenticationRequired(_NewListWizardPage);

export default NewListWizardPage;
