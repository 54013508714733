import { Dayjs } from 'dayjs';
import { GridColDef, GridSortItem } from '@mui/x-data-grid-premium';
import { EDPProspectList } from 'adapters/types';
import { OwnerNameAndListName } from 'hooks/EDP/useProspectsMap';

export const enum TableTypesKind {
  Attendee = 'attendee',
  Sponsor = 'sponsor',
}

// #region Table types
export interface EDPTable extends TableBase {
  data: TableRow[];
  type: TableTypesKind;
}

export type EDPTableUnion = TableAttendees | TableSponsors;
export interface TableAttendees extends TableBase {
  data: TableRowAttendee[];
  type: TableTypesKind.Attendee;
}
export interface TableSponsors extends TableBase {
  data: TableRowSponsor[];
  type: TableTypesKind.Sponsor;
}
interface TableBase {
  columns: GridColDef[];
  sortModel: GridSortItem[];
  setSortModel: (sortModel: GridSortItem[]) => void;
  loading: boolean;
  authorized: boolean;
  count: number;
  setCount?: (count: number) => void;
}

// #endregion

// #region Table Row types

export type TableRow = TableRowSponsor | TableRowAttendee;
interface TableRowBase {
  id: string; // for data grid
  organization: string;
  added: string; // date added to our databases
  image: string;
  prospect: boolean;
  dayjs: Dayjs;
  status: StatusFilterKind;
  prospectLists: OwnerNameAndListName[];
  deal_info: DealInfo[];
  number_of_deals: {
    amount: number;
    from_crm: boolean;
  };
  pipeline_value: {
    amount: number;
    from_crm: boolean;
  };
  avg_deal_size_source: DealSource;
}

export interface DealInfo {
  deal_id: number;
  deal_owner_id: number;
  deal_owner_name: string;
  deal_stage: string;
}

export enum DealSource {
  List = 'LIST',
  Team = 'TEAM',
  OrgDeal = 'ORG_DEAL',
  Default = 'DEFAULT',
}

export type EmployeesRange =
  | ''
  | '0-10'
  | '11-50'
  | '51-250'
  | '251-1K'
  | '1K-5K'
  | '5K-10K'
  | '10K-50K'
  | '50K-100K'
  | '100K+';
export interface TableRowAttendee extends TableRowBase {
  title: string;
  jobtitle: string;
  name: string;
  org_slug: string;
  org_uuid: string;
  person_url: string;
  profile_slug: string;
  profile_uuid: string;
  speaking: boolean;
  is_sponsor: boolean;
  type: TableTypesKind.Attendee;
  cost: number; // only attendees cost $ to enrich
  employees_range: EmployeesRange;
}
export interface TableRowSponsor extends TableRowBase {
  events: number;
  members: number;
  slug: string;
  uuid: string;
  website: string;
  type: TableTypesKind.Sponsor;
}

export type SelectedFilterMap = Map<string, boolean>;

// #endregion

export type ProspectListMap = Map<string, EDPProspectList>; // map of Prospect List UUID -> ProspectList

export enum SpeakerFilterKind {
  Neither = 'neither',
  Speaking = 'speaking',
  NotSpeaking = 'not speaking',
}

export enum StatusFilterKind {
  None = 'None',
  Predicted = 'Predicted',
  Confirmed = 'Confirmed',
  Historical = 'Historical',
}

export enum DateFilterKind {
  ThreePlusMonthsFromNow = '3+ months from now',
  Next3Months = 'Next 3 months',
  Next30Days = 'Next 30 days',
  NextWeek = 'Next 7 days',
  Today = 'Today',
  LastWeek = 'Last 7 days',
  Last30Days = 'Last 30 days',
  Last3Months = 'Last 3 months',
  ThreePlusMonthsAgo = '3+ months',
  None = 'None',
}
