export const number_of_events = '200k';

export const number_of_rows_csv_upload_for_prospect = '10,000';

export const AUTH0_CONFIG = !process.env.REACT_APP_USE_DEVELOPMENT_AUTH0
  ? {
      domain: 'id.vendelux.com',
      audience: 'https://vendelux/events',
      client_id: 'cEagNSof30Vj1qtd6MENpQZC86AhLxoP',
    }
  : {
      domain: 'dev-zr45k0ipxyvn7p2n.us.auth0.com',
      audience: 'https://vendelux/events',
      client_id: 'HnRLymz6uHOWEkAFuYasA8PGjRtja8D4',
    };

export const ANALYTICS_ID = process.env.REACT_APP_ANALYTICS_ID;

export const LIMIT_EXPORTS_PER_DAY = 5;

export const EDP_AVATAR_PLACEHOLDER_URL =
  'https://i.vendelux.com/G4yFOqx_rgWFa-OVw3mPKIgprDU=/40x40/media/pic_folder/placeholders/7.png';

// list of UUIDs to deny stuff for
export const DENY_UUIDS = {
  // add a team UUID here, and the prospect upload CSV tab will disappear
  prospect_upload_csv: [
    'cd8886bd-1185-4822-aa75-3a4f0f191b23', // Splash DEV-2091
    '72c42290-ffe8-43d3-b893-2920c79665fa', // Splash DEV-2091
    // '78d8061a-dd76-497a-a84e-d1d32be45170', // Vendelux (for testing)
  ],
};

export const SEARCH_DEBOUNCE_MS = 500;

export const MIN_MAX_DATE_NUM = 864e13;

export const ICP_UPLOAD_MAX_ROWS = 200;

// DEV-3131
export const ICP_ALLOWED_ORG_TEAM_UUIDS = [
  '78d2c4ba-051d-45b1-80f1-ddfb1048ea8b', // veho team uuid
  '758e7ffd-a097-41d8-b66d-9d7b4975cd0a', // ubiquity team uuid
  '166988ba-fec8-4aea-9637-db84bfd4de94', // kustomer team uuid
  '837b659b-106c-4356-8ad7-7d782a22d539', // talon one team uuid
  'a8e7704d-1eee-4ddf-88bd-57eda6468d24', // pixis team uuid
  'f872ddac-ea1a-4d6b-8d3a-f3315f229608', // yubico team uuid
];

// upgrade messages
export const FREE_AND_PRO_UPGRADE_MESSAGE =
  'Upgrade your plan to see ROI information.';

export const PLUS_UPGRADE_MESSAGE =
  'This is an estimate. Upgrade your plan to see ROI information from your CRM.';

// A hardcoded limit to how many sponsors + attendees you can search for at once. to prevent giant ICP lists from crashing the page
export const MAX_EXPLORE_ALGOLIA_PROFILES_AND_ORGS = 1000;
