import { Chip, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode, useCallback } from 'react';
import { formatNumber } from 'utils/util';
import {
  CustomGridColDef,
  DEFAULT_VALUES_FOR_PROPS,
} from '../../DataGridProvider';
import { GridValidRowModel } from '@mui/x-data-grid-premium';
import CheckIcon from '@mui/icons-material/Check';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import CloseIcon from '@mui/icons-material/Close';

interface DataGridFilterButtonChipProps<T extends GridValidRowModel> {
  item: FilterChipItem;
  selectItem: (item: FilterChipItem) => void;
  column: CustomGridColDef<T>;
  hideCount?: boolean;
  itemIsSelected?: boolean;
  startIcon?: ReactNode;
}

export interface FilterChipItem {
  label: string;
  value: unknown;
  count: number;
  rowIds?: string[];
}

export const checkTwoChipsIfEqual = (
  chipA: FilterChipItem,
  chipB: FilterChipItem
) =>
  chipA.label === '' || chipA.label === '0' || chipA.label === 'Undefined'
    ? chipB.label === 'Undefined' || chipB.label === '' || chipB.label === '0'
    : chipA.value === chipB.value;

export const DataGridFilterButtonChip = <T extends GridValidRowModel>({
  item,
  selectItem,
  column,
  hideCount,
  itemIsSelected,
  startIcon,
}: DataGridFilterButtonChipProps<T>) => {
  const theme = useTheme();

  const filterChipTruncateLength =
    column.filterChipTruncateLength ??
    DEFAULT_VALUES_FOR_PROPS.filterChipTruncateLength;
  const filterChipBlankText =
    column.filterChipBlankText ?? DEFAULT_VALUES_FOR_PROPS.filterChipBlankText;

  // cut off the label if it's too long and show ellipsis
  const label =
    item.label.length > filterChipTruncateLength
      ? `${item.label.slice(0, filterChipTruncateLength)}...`
      : item.label;

  const count = Number(item.count) ?? 0;
  const isBlankChip = label.length === 0 || item.value === undefined;

  const renderStartIcon = useCallback(() => {
    if (startIcon) {
      return startIcon; // return startIcon if it's provided
    } else if (isBlankChip) {
      return <NotInterestedIcon fontSize="small" />; // return NotInterestedIcon if it's a blank chip
    } else if (typeof column.getFilterChipStartIcon === 'function') {
      return column.getFilterChipStartIcon(item.value); // run the function if it's provided
    } else if (column.type === 'boolean' && column.booleanLabel) {
      return item.label === column.booleanLabel[0] ? (
        <CheckIcon fontSize="small" />
      ) : (
        <CloseIcon fontSize="small" />
      );
    }
    return null;
  }, [startIcon, column, item, isBlankChip]);

  // hide the chip if it's the 'None' / blank chip
  if (column.hideBlankFilterChip && isBlankChip) return null;

  return (
    <Chip
      label={
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          spacing={1}
          sx={{
            opacity: count === 0 && !itemIsSelected ? 0.5 : 1,
          }}
        >
          {renderStartIcon()}
          <Typography variant="body2">
            {label.length === 0 || item.value === undefined
              ? filterChipBlankText
              : label}
          </Typography>
          {!hideCount ? (
            <Typography
              variant="caption"
              sx={{
                bgcolor: itemIsSelected
                  ? theme.palette.common.white
                  : theme.palette.grey[300],
                color: itemIsSelected
                  ? theme.palette.primary.main
                  : theme.palette.grey[800],
                borderRadius: '100vh',
                px: 2,
                borderWidth: count === 0 ? 1.5 : 0,
                borderColor: count === 0 ? theme.palette.error.main : 'none',
                borderStyle: count === 0 ? 'solid' : 'none',
              }}
            >
              {formatNumber(count)}
            </Typography>
          ) : null}
        </Stack>
      }
      color={itemIsSelected ? 'primary' : 'default'}
      onClick={() => {
        selectItem(item);
      }}
      variant={itemIsSelected ? 'filled' : 'outlined'}
      data-testid="filter-chip"
      data-count={count}
    />
  );
};
